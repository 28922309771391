//
//-- General mixins and functions
//

@function em($pixels, $ref: 16) {
	@return ($pixels / $ref) * 1em;
}

@function rem($pixels, $ref: 16) {
	@return ($pixels / $ref) * 1rem;
}

@mixin vertical-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin hover-underline {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

@mixin hover-border {
	border: 3px solid transparent;
	transition: border 0.2s ease;

	&:hover {
		border-color: $color-base-light;
	}
}