//
//-- Welcome
//

.welcome {
	background: $color-base url(../images/welcome_background.jpg) no-repeat center center;
	background-size: cover;
	position: relative;
	height: em(150);
    
    @include tablet-port {
       height: em(260); 
    }
    
    @include wide {
       height: em(450); 
    }
    
    @include very-wide {
       height: em(500); 
    }
    
    @include retina {
        background-image: url(../images/welcome_background@2x.jpg);
    }
}

.welcome__title {
	font-size: rem(70);
	text-transform: uppercase;
	font-weight: 500;
	color: #FFF;
	text-shadow: 2px 2px 2px rgba(1,1,1,1.75);
	line-height: 1;
	text-align: center;
	@include vertical-center;
    
    @include tablet-port {
       font-size: rem(100); 
    }
    
    @include wide {
       font-size: rem(200); 
    }
}

.welcome__title-complement {
	font-size: rem(30);
	display: block;
	font-weight: 600;
    
    @include tablet-port {
       font-size: rem(50); 
    }
    
    @include wide {
       font-size: rem(100); 
    }
}