//
// -- Reset Styles
//

*, *::before, *::after {
	box-sizing: border-box;
}

* {
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}
h1, h2 {
    text-shadow: 1px 1px 1px rgba(0,0,0,1);
}

ul {
	list-style: none;
}

img, video, embed, object {
	max-width: 100%;
}

img {
	vertical-align: middle;
}

label, button {
	cursor: pointer;
}