//
//- Grid Systems
//

$grid-gutter: 16px;

.grid {
	margin-right: ($grid-gutter * -1);
	margin-left: ($grid-gutter * -1);
}

// smallest grid
@include generate_grid(xs, $grid-gutter);

// small grid
@include phone-land {
	@include generate_grid(sm, $grid-gutter);
}

// medium grid
@include tablet-port {
	@include generate_grid(md, $grid-gutter);
}

// large grid
@include tablet-land {
	@include generate_grid(lg, $grid-gutter);
}