//
//-- Helper Classes
//

.ac {
	text-align: center;
}

.ma {
	margin-left: auto;
	margin-right: auto;
}

.site-width {
	max-width: $site-width;
}

.clearfix, .grid {
	&::before, &::after {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}
}