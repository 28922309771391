//
//-- Config
//

// colors
//$color-base: #000; 
//$color-base-text: #999; 
//$color-base-light: #cbb09a; // Pexoll logo bg: #e2c4ac       10% zw: #cbb09a     15% zw: #c0a792
//$color-base-logo-light: #f9f3ed; // Pexoll logo light
//$color-gradient1: #000;
//$color-gradient2: #cbb09a; // Pexoll logo bg: #e2c4ac       10% zw: #cbb09a     15% zw: #c0a792
//$color-highlight: #FFF000;

$color-base: #FFF; 
$color-base-text: #999; 
$color-base-light: #cbb09a; // Pexoll logo bg: #e2c4ac       10% zw: #cbb09a     15% zw: #c0a792
$color-base-logo-light: #f9f3ed; // Pexoll logo light
$color-gradient1: #FFF;
$color-gradient2: #cbb09a; // Pexoll logo bg: #e2c4ac       10% zw: #cbb09a     15% zw: #c0a792
$color-highlight: #FFF000;

// widths
$smallest-width: 20em;
$very-small-width: 30em;
$small-width: 48em;
$medium-width: 60em;
$large-width: 75em;
$very-large-width: 100em;

$site-width: 75em;
