//
//-- Portfolio
//

.portfolio__nav {
	text-align: center;
	margin-bottom: 2em;
    
    @include tablet-port {
        text-align: left;
    }
}

.portfolio__nav-li {
    display: inline-block;
    
    @include tablet-port {
        display: block;
    }
}

.portfolio__nav-item {
	color: $color-base-logo-light;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
	transition: color 0.2s ease;
	display: inline-block;
	font-size: em(16);
	margin: 0.3em 0.8em;

	&:hover, &.is-active {
		color: $color-base-light;
        font-weight: 900;
	}
    
    @include tablet-port {
        display: block;
        font-size: em(22);
	    margin: 0 0 1.25em;
    }  
}

.portfolio__item {
	display: block;
	margin-bottom: 2em;
	text-decoration: none;
	@include hover-border;

	img {
		max-width: none;
		width: 100%;
	}
}