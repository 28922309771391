//
//-- Blog
//

.blog__item {
	font-size: em(14);
	margin-bottom: 2em;
    
    @include tablet-port {
        font-size: em(16);
	    margin-bottom: 3em;
    }
    
    @include wide {
        font-size: em(18);
	    margin-bottom: 4em;
    }
}

.blog__item-image-container {
	margin-bottom: 1.5em;

	a {
		display: block;
		@include hover-border;
	}

	img {
		max-width: none;
		width: 100%;
	}
}

.blog__item-header, .blog__item-excerpt {
	padding: 0 3px;
}

.blog__item-tag {
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	color: $color-base-light;
	margin-bottom: 0.5em;
	@include hover-underline;
}

.blog__item-title {
	font-weight: bold;
	color: $color-base-light;
	font-size: em(20);
	margin-bottom: 1rem;

	a {
		color: inherit;
		@include hover-underline;
	}
}

.blog__item-excerpt {
	color: $color-base-text;
	line-height: 1.9;
	
	p {
		margin-bottom: 1.5em;
        
        a {
		  color: $color-base-light;
         
            &:hover {
                color: $color-base-logo-light;
            }
	    }
	}
}

.blog__item-read {
	display: block;
	text-align: right;
	color: $color-base-light;
	font-weight: bold;
	@include hover-underline;

	&::after {
		content: " \00bb";
	}
}