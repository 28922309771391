//
//-- Header
//

.page-nav {
	background: $color-base-light;
	color: #000;
	text-align: center;
	padding: 1em 0 0;
    
    @include tablet-port {
        padding: 1.5em;
    }
}

.page-nav__li {
	position: relative;
    border-bottom: 1px solid #CCC;
    
    @include tablet-port {
        border: 0;
	    display: inline-block;
        
        &::after {
            content: "-";
            position: absolute;
            top: 50%;
            right: -0.15em;
            transform: translate(0, -50%);
        }

        &:last-child::after {
            display: none;
        }    
    }
}

.page-nav__item {
	display: block;
	color: inherit;
	text-decoration: none;
	text-transform: uppercase;
	transition: opacity 0.2s ease;
	padding: 0.5em 1em;

	&:hover {
		opacity: 0.7;
	}
    
    @include tablet-port {
      font-size: em(18);
      padding: 1em 2em;
    }
}

.page-nav__hamburger {
    display: inline-block;
    cursor: pointer;
    transition: transform 0.2s ease;
    margin-bottom: 1em;
    
    &.hover {
        transform: scale(1.2)
    }
    
    @include tablet-port {
      display: none;
    }
    
}

.page-nav__ul {
   display: none;
    
    @include tablet-port {
      display: block !important;
    }
}