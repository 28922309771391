//
//-- General Typography
//

html, body {
	background: $color-base;
	color: $color-base-text;
}

body, input, select, textarea, button {
	font-family: "Open Sans", sans-serif;
	font-size: 100%;
}