//
//-- Grid Mixins
//

@mixin generate_grid($type, $gutter) {
	%grid-#{$type}--base {
		float: left;
		padding-left: $grid-gutter;
		padding-right: $grid-gutter;
	}

	@for $i from 1 through 12 {
		.grid-#{$type}--#{$i} {
			@extend %grid-#{$type}--base;
			width: (100% / 12) * $i;
		}
	}
}