//
//-- General sections
//

.section {
	margin-bottom: 1.5em;
    padding: 0 1.25em;
    
    @include tablet-port {
    	margin-bottom: 2em;
    }
    
    @include wide {
    	margin-bottom: 3em;
        padding: 0;    
    }
}

.section__title {
	text-transform: uppercase;
	color: $color-base-light;
	font-weight: bold;
	text-align: center;
	margin-bottom: 1.5rem;
	font-size: em(32);
    
    @include tablet-port {
	    font-size: em(42);
    }
    
    @include wide {
	    font-size: em(50);    
    }
}

.section__intro-text {
	color: $color-base-text;
	text-align: justify;
	font-size: em(14);
	margin-bottom: 1.5rem;
        
    a {
        color: $color-base-text;
 
        &:hover {
            color: $color-base-logo-light;
        }
    }
        
    @include tablet-port {
	    font-size: em(16);
        margin-bottom: 3rem;
    }
    
    @include wide {
	    font-size: em(18);
        margin-bottom: 4rem;
    }
}
        
ul.section__intro-text {
   list-style-type: square;
}